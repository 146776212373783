<template>
  <b-overlay :show="loading" opacity="0.5">
    <div>
      <list-table
        v-if="showListTable"
        :records="policies"
        :columns="tableColumns"
        :total-records="totalRecords"
        :current-page-number.sync="currentPage"
        :search-filter.sync="searchFilter"
        @row-clicked="onItemClicked"
      >
        <template #action_button>
          <b-button variant="primary" :to="{ name: 'admin-new-policy' }">
            <span class="text-nowrap">New Policy</span>
          </b-button>
        </template>

        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{
                name: 'admin-view-policies',
                params: { id: data.item._id },
              }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Update</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="deletePolicy(data.item._id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </list-table>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";

import { debounce, get } from "lodash";
import { formatDate } from "@core/utils/utils";

import Swal from "sweetalert2";
import vSelect from "vue-select";
import useJwt from "@/auth/jwt/useJwt";
import Ripple from "vue-ripple-directive";
import FileDownload from "js-file-download";
import ListTable from "@core/components/ListTable/ListTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const watchHandler = {
  handler() {
    this.debounceFetchAllData();
  },
  immediate: false,
};

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BInputGroupAppend,

    vSelect,
    BOverlay,
    ListTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tableColumns: [
        { key: "name", sortable: false },
        { key: "description", sortable: false },
        { key: "version", sortable: false },
        {
          key: "statements",
          sortable: false,
          label: "# Statements",
          formatter: (val) => val.length,
        },
        {
          key: "created",
          sortable: false,
          formatter: (val) => `${formatDate(val)}`,
          label: "Date",
        },
        { key: "actions" },
      ],
      statusOptions: [
        { label: "Active", value: "active" },
        { label: "In-Active", value: "inactive" },
        { label: "Draft", value: "draft" },
        { label: "Deleted", value: "deleted" },
      ],
      statusFilter: "",
      searchFilter: "",
      loading: false,
      showListTable: false,
      debounceFetchAllData: () => {},
      policies: []
    };
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
  },
  watch: {
    perPage: {
      handler() {
        this.currentPage = 1;
        this.debounceFetchAllData();
      },
      immediate: false,
    },
    currentPage: watchHandler,
    statusFilter: watchHandler,
    searchFilter: watchHandler,
  },
  created() {
    this.debounceFetchAllData = debounce(this.fetchClientGroups, 500);
    
    const { 
      status, 
      page = 1, 
      search = "", 
      limit = this.perPage 
    } = this.$route.query;

    this.currentPage = +page;
    this.searchFilter = search;
    this.statusFilter = status;
    this.perPage = +limit;
  },
  methods: {
    onItemClicked(rowItem) {
      this.$router.push({
        name: "admin-view-policies",
        params: { id: rowItem._id },
      });
    },
    async fetchClientGroups() {
      try {
        this.loading = true;

        const {
          perPage: limit,
          currentPage: page,
          statusFilter: status,
          searchFilter: search,
        } = this;

        const query = {
          limit,
          page,
          status,
          search,
        };
        this.$router.push({ query }).catch(() => {});

        const request = await useJwt.adminService.fetchAdminPolicies(query);
        const { data, pagination } = request.data;

        this.totalRecords = pagination.totalRecords;
        this.policies = data;
      } catch (error) {
        const error_message =
          get(error, "response.data.message") || error.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
        return [];
      } finally {
        this.loading = false;
        this.showListTable = true;
      }
    },
    async onExportClientGroups() {
      try {
        this.loading = true;

        const result = await Swal.fire({
          title: "Confirm Export?",
          text: "This will export data based on current filters.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, proceed.",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        const query = {
          status: this.statusFilter,
        };

        const response = await useJwt.adminService.exportClientGroups(query);
        FileDownload(response.data, "client-groups-export.csv");
      } catch (error) {
        const error_message =
          get(error, "response.statusText") || error.message;
        this.$toast.error(error_message);
      } finally {
        this.loading = false;
      }
    },
    async deletePolicy(policy_id){
      try {
        this.loading = true;

        const deleteConfirmation = await this.$swal({
          title: 'Delete this policy?',
          text: "You are about to delete a policy. Continue?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (!deleteConfirmation.value){
          return;
        }

        await useJwt.adminService.deleteAdminPolicy(policy_id);
        this.$router.go();
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
.width-100 {
  width: 100px;
}
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
.loan-list-tb-row {
  cursor: pointer !important;
}
</style>
